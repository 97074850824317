import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { PhotoUrl } from 'manybuildlogic';
import ImagePlaceholder from '../../assets/placeholder.png';
import { CheckResponseStatus } from './CheckResponseStatus';

interface PictureDisplayProps {
  photos: PhotoUrl[];
  room?: boolean;
  title: string;
  handleClick: (ogUrl: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PictureDisplay: React.FC<PictureDisplayProps> = props => {
  const { photos, room, title } = props;
  const maxSteps = photos.length;
  const [activeStep, setActiveStep] = React.useState(0);
  const [imgType, setImgType] = React.useState<'original' | 'processed'>('processed');
  console.log(photos);

  if (maxSteps > 0) {
    CheckResponseStatus(photos[activeStep].processed)
      .then(res => {
        if (res) {
          setImgType('processed');
        } else {
          setImgType('original');
        }
      })
      .catch(err => console.log(err));
  }

  const handleChange = (forward: boolean) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (forward) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const img = maxSteps === 0 ? ImagePlaceholder : photos[activeStep][imgType];

  if (room) {
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <CardMedia
          component="div"
          image={img}
          title={title}
          style={{
            height: 300,
            width: '100%',
            objectFit: 'contain', // one of these two contains fixed the problem with scaling
            backgroundSize: 'contain', // I think this one but idk
          }}
        />
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleChange(true)}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleChange(false)} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width: '60%',
      }}
    >
      <CardMedia
        component="div"
        image={img}
        title={title}
        style={{
          height: 850,
          objectFit: 'contain', // one of these two contains fixed the problem with scaling
          backgroundSize: 'contain', // I think this one but idk
        }}
        onClick={maxSteps > 0 ? props.handleClick(photos[activeStep].original) : undefined}
      />
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleChange(true)} disabled={activeStep === maxSteps - 1}>
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleChange(false)} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </div>
  );
};

export default PictureDisplay;
