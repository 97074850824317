import React from 'react';
import TopNav from './TopNav';
import Footer from './Footer';
import Mixpanel from '../Mixpanel';
import ErrorBoundary from '../Errors/ErrorBoundary';
import './Page.css';

const Page: React.FC<{ title?: string }> = props => {
  // track referrer from here; if document.referrer contains 'manybuild', ignore it
  Mixpanel.init();
  Mixpanel.track('Page view', { title: props.title });
  return (
    <ErrorBoundary>
      <TopNav />
      <div id="content_container">
        {props.title ? <h1 id="page_title">{props.title}</h1> : null}
        <div id="body_container">{props.children}</div>
      </div>
      <Footer />
    </ErrorBoundary>
  );
};

export default Page;
