import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { DisplayProject, CardType, RoomCard } from 'manybuildlogic';
import Chip from '@material-ui/core/Chip';
import { Trans } from '@lingui/macro';
import Popover from '@material-ui/core/Popover';
import PictureDisplay from './PictureDisplay';

interface ProjectCardProps {
  project: DisplayProject;
}

const ProjectCard: React.FunctionComponent<ProjectCardProps> = (props: ProjectCardProps) => {
  const contactCard = props.project.contact;

  console.log(props.project.photos);

  const photos = Object.keys(props.project.photos).map(k => props.project.photos[k]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [currPhotoUrl, setPhotoUrl] = React.useState<string>('');

  const handleClick = (ogUrl: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPhotoUrl(ogUrl);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPhotoUrl('');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div
      style={{
        margin: '0 auto',
        display: 'flex',
        boxShadow:
          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12',
      }}
    >
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {open && currPhotoUrl.startsWith('https') ? (
          <img src={currPhotoUrl} alt="full size" />
        ) : null}
      </Popover>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '40%',
          padding: '2%',
        }}
      >
        <h3>{props.project.title}</h3>
        <p>{contactCard.company}</p>
        <p>{props.project.projectType}</p>
        <p>{props.project.description}</p>
        <Grid container spacing={1} style={{ paddingBottom: '5px' }}>
          {props.project.trades.length > 0 ? (
            props.project.trades.map(trade => (
              <Grid item key={trade.trade} style={{ textAlign: 'center' }}>
                <Chip label={trade.trade} style={{ margin: 'auto', overflow: 'visible' }} />
              </Grid>
            ))
          ) : (
            <p style={{ color: '#0952aa' }}>
              <Trans>No trades listed</Trans>
            </p>
          )}
        </Grid>
      </div>
      <PictureDisplay photos={photos} title={props.project.title} handleClick={handleClick} />
    </div>
  );
};

interface RoomProps {
  rm: RoomCard;
}

const Room: React.FunctionComponent<RoomProps> = (props: RoomProps) => {
  const { rm } = props;
  // PictureDisplay(rm.photoUrls, true, rm.name)}
  return (
    <Grid item xs={12} md={8} lg={3} className="project-card">
      <Card style={{ height: 600, marginTop: '15px' }}>
        <h4 style={{ textAlign: 'center' }}>{rm.name} Photos</h4>
        <CardContent style={{ height: 120 }} className="cardDescription">
          <Typography variant="body2" color="textPrimary" component="div" style={{ height: 60 }}>
            <p>{rm.description}</p>
          </Typography>

          {rm.area && rm.area > 0 ? (
            <Typography variant="body2" color="textPrimary" component="div">
              <p>Area: {rm.area} square feet</p>
            </Typography>
          ) : null}

          {rm.height && rm.height > 0 ? (
            <Typography variant="body2" color="textPrimary" component="div">
              <p>Height: {rm.height} feet</p>
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    </Grid>
  );
};

export { Room };
export default ProjectCard;
